import React, { useEffect } from 'react';
import Header from '../Header/Header';
import AboutUs from './AboutUs/AboutUs';
import Kitabs from './Kitabs/Kitabs';
import Pillars from './Pillars/Pillars';
import YoutubePromo from './YoutubePromo/YoutubePromo';

const Home = () => {


    useEffect(() => {
        let deviceType = "";
        if (window.screen.width >= 991) {
            deviceType = "computer";
        }
        else if (window.screen.width > 600 && window.screen.width < 991) {
            deviceType = "tablet";
        }
        else if (window.screen.width <= 600) {
            deviceType = "phone";
        }

        // fetch(`https://talkingbookbangla.org/audiobanglahadis/addView.php?device=${deviceType}&width=${window.screen.width}`)
        //     .then(res => res.json())
        //     .then(data => {
        //     })
    }, [])

    return (
        <div>
            <Header></Header>
            <Kitabs></Kitabs>
            <Pillars></Pillars>
            <YoutubePromo></YoutubePromo>
            <AboutUs></AboutUs>
        </div>
    );
};

export default Home;