import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import './HadithChapters.css';
import headerBG from '../../Images/chapter-banner.jpg';
import ChapterCard from './ChapterCard/ChapterCard';
import Loading from '../Loading/Loading';

const HadithChapters = () => {
    const { kitab } = useParams();
    const [chapters, setChapters] = useState([])
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState();

    useEffect(() => {
        setIsLoading(true);
        const url = `https://hadisbangla.com/backend/parseChapters.php?kitab=${kitab}`;
        fetch(url)
            .then(res => res.json())
            .then(data => {              
                setChapters(data);
                setIsLoading(false);
            });
    }, [kitab]);

    const chapterCardOnClick = id => {
        navigate(`${pathname}/${id}`)
    }

    return (
        <div>
            <div className="hadith-chapters-header p-5" style={{ backgroundImage: `url(${headerBG})` }}>
                <h1 className="text-center text-light fw-bold">সহীহ আল-বুখারী</h1>
            </div>
            <div className="container my-5">
                {
                    isLoading ? (
                        <Loading text={"অধ্যায়সমূহ"}></Loading>
                    )
                        :
                        (
                            <div className="row row-cols-2 row-cols-md-4 row-cols-lg-6 g-3 justify-content-center my-5">
                                {
                                    chapters?.map(chapter => <ChapterCard
                                        key={chapter.chapterNo}
                                        chapter={chapter}
                                        chapterCardOnClick={chapterCardOnClick}
                                    >
                                    </ChapterCard>)
                                }
                            </div>
                        )
                }

            </div>            
        </div>
    );
};

export default HadithChapters;