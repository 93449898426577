import React from 'react';
import './AboutUs.css';
import headerBG from '../../../Images/header.jpg';

const AboutUs = () => {
    return (
        <div className='container mt-5 pt-5'>
            <h1
                style={{ borderImage: `url(${headerBG}) 30 stretch` }}
                className="kitabs-title">আমাদের সম্পর্কে কিছু কথা</h1>
            <div className='my-5'>
                <h4 className='about-us-text'>‘অডিও বাংলা হাদিস’ ভিউ ফাউন্ডেশন পরিচালিত বিনামূল্যে হাদিসের বাংলা অনুবাদ শোনার এবং পড়ার একটি ডিজিটাল মাধ্যম। 
                    আমরা আমাদের ইউটিউব চ্যানেল এবং ওয়েবসাইটের মাধ্যমে সকলের কাছে হাদিসের বাংলা অনুবাদ
                    পৌঁছে দেয়ার চেষ্টা করে যাচ্ছি। মূলত ২০১২ সালে দৃষ্টি প্রতিবন্ধীদের জন্য সহীহ আল-বুখারী শরীফের
                    বাংলা অনুবাদ রেকর্ডিং করার মাধ্যমে আমাদের যাত্রা শুরু হয়। পরবর্তীতে জামি আত তিরমিজী কিতাব 
                    রেকর্ডিং সম্পন্ন করা হয়। <br /><br />
                    বিভিন্ন সময় বিনামূল্যে মেমোরী কার্ড বিতরণের মাধ্যমে দৃষ্টি প্রতিবন্ধীদের কাছে হাদিসসমূহের বাংলা অনুবাদ পৌঁছে দেয়া হয়।
                    পাশাপাশি আমাদের ওয়েবসাইটের মাধ্যমে হাদিস ডাউনলোড করে দৃষ্টি প্রতিবন্ধীরা শুনতে পারতেন। পরবর্তীতে ২০১৮ সাল থেকে 
                    সাধারন মানুষের কাছে সহীহ আল-বুখারী শরীফের বাংলা অনুবাদ সহজলভ্য ইউটিউব চ্যানেলের মাধ্যমে নিয়মিত ভিডিও প্রকাশ করা হয়।
                    <br /><br />
                    বর্তমানে ২০২২ সালে আমাদের ‘অডিও বাংলা হাদিস’ ওয়েবসাইটি সংস্কার করে ওয়েবসাইটে হাদিস পড়ার এবং শোনার ফিচার নিয়ে
                    আশা হয়, যাতে খুব সহজেই নিজের মত করে অধ্যায়ভিত্তিক হাদিস শোনা এবং পড়া যাবে। আমরা খুব শিগ্রই একটি 
                    মোবাইল অ্যাপ পাবলিশ করবো ইনশাআল্লাহ, যেন আমাদের নিয়মিত এবং বয়স্ক শ্রোতাগণ খুবই সহজে হাদিসের বাংলা অনুবাদ শুনতে এবং পড়তে পারেন।
                    </h4>
            </div>

        </div>
    );
};

export default AboutUs;