import React from 'react';
import './Header.css';
import headerBG from '../../Images/header.jpg';

const Header = () => {
    return (
        <div>
            <div
                style={{ backgroundImage: `url(${headerBG})` }}
                id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner container text-light d-flex align-items-center">
                    <div className="carousel-item active">
                        <h2>আমাদের ওয়েবসাইট থেকে বিনামূল্যে এবং বিজ্ঞাপন ব্যতীত হাদিস শুনতে পারবেন এবং পড়তে পারবেন</h2>

                    </div>
                    <div className="carousel-item">
                        <h2>আমাদের সম্মানিত শ্রোতাগণ যেন হাদিস আরো ভালভাবে শুনতে পারেন, সে লক্ষ্যে আমরা বিজ্ঞাপনমুক্ত মোবাইল অ্যাপ তৈরি করেছি।</h2>
                    </div>
                    <div className="carousel-item">
                        <h2>আমাদের ইউটিউব চ্যানেলের ভিডিগুলো থেকে হাদিসগুলো পড়তে এবং শুনতে পারবেন। </h2>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default Header;