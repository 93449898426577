import React from 'react';
import './Kitabs.css';
import headerBG from '../../../Images/header.jpg';
import BukhariThumbnail from '../../../Images/Bukhari.jpg';
import TirmidhiThumbnail from '../../../Images/tirimzi.jpg';
import { useNavigate } from 'react-router';


const Kitabs = () => {

    let navigate = useNavigate();
    const handleKitabOnClick = path => {
        navigate(`chapters/${path}`);
        window.scrollTo(0, 0);
    }



    return (
        <div className="kitabs container py-5">
            <h1 className="kitabs-title"
                style={{ borderImage: `url(${headerBG}) 30 stretch` }}
            >হাদিস গ্রন্থসমূহ</h1>
            <div className="row row-cols-1 row-cols-md-3 g-3 justify-content-center my-5">
                <div onClick={() => handleKitabOnClick('bukhari')} className="col">
                    <div className="card h-100 px-3">
                        <img src={BukhariThumbnail} className="img-fluid p-5 p-md-4" alt="..." />
                        <div className="card-body pb-0">
                            <h5 className="card-title fs-3 pb-0">সহীহ আল-বুখারী</h5>
                        </div>
                        <div className="card-footer">
                            <p>হাদিস সংখ্যা: ৭০৫৩টি</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card h-100 px-3">
                        <img src={TirmidhiThumbnail} className="img-fluid p-5 p-md-4" alt="..." />
                        <div className="card-body pb-0">
                            <h5 className="card-title fs-3">জামি আত তিরমিজী</h5>
                        </div>
                        <div className="card-footer">
                            <p>হাদিস সংখ্যা: ৪৩৫৩টি</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kitabs;