import React, { useEffect } from 'react';
import './Footer.css';

const Footer = () => {

    useEffect(() => {
        document.getElementById("year").innerHTML = new Date().getFullYear();
    }, [])


    return (
        <div className='footer p-lg-5 p-2 pb-lg-0 pb-0'> 
            <div className="row">
                <div className="col-md-4 p-4">
                    <h4>অডিও বাংলা হাদিস</h4>
                    <p>আমাদের লক্ষ্য বিনামূল্যে হাদিসের অনুবাদ বাংলা ভাষায় সকলের কাছে পৌঁছে দেয়া। আমাদের ওয়েবসাইট বা ইউটিউব চ্যানেল থেকে হাদিস পড়তে এবং শুনতে পারবেন।</p>
                </div>
                <div className="col-md-4 p-4">
                    <h5>আমাদের অন্যান্য ওয়েবসাইট</h5>
                    <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/AudioBanglaHadis'>অডিও বাংলা হাদিস ইউটিউব</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href='https://viewfoundationbd.org/'>ভিউ ফাউন্ডেশন</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href='https://talkingbookbangla.org/'>টকিং লাইব্রেরী</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href='https://sightforallbd.org/'>সাইট ফর অল</a></li>
                    </ul>
                </div>
                <div className="col-md-4 p-4">
                    <h5>যোগাযোগ</h5>
                    <p className='mb-1'>৩/গ (১ম তলা), বসতি হাউজিং, রোড-৪, বড়বাগ, মিরপুর-২, ঢাকা-১২১৬।</p>
                    <p className='mb-1'>মোবাইল: 01917002289, 01701790621</p>
                    <p className='mb-1'>ই-মেইল: viewfoundationbd@gmail.com</p>
                </div>
            </div>
            <div className="row text-center pb-3">
                <div className="col-12">
                    একটি <a target="_blank" rel="noopener noreferrer" href='https://viewfoundationbd.org/'>ভিউ ফাউন্ডেশন</a> প্রজেক্ট || © <span id='year'></span>
                </div>
            </div>
        </div>
    );
};

export default Footer;