import React from 'react';
import './YoutubePromo.css';
import headerBG from '../../../Images/header.jpg';
import YoutubeImage from '../../../Images/youtube.jpg';

const YoutubePromo = () => {
    return (
        <div className='container pt-5'>
            <h1
                style={{ borderImage: `url(${headerBG}) 30 stretch` }}
                className="kitabs-title">আমাদের ইউটিউব চ্যানেল</h1>

            <div className='row my-4'>
                <div className="col-md-6 p-4">
                    <div className='text-center'>
                        <a href="https://www.youtube.com/c/AudioBanglaHadis" target="_blank" rel="noopener noreferrer">
                            <img className='img-fluid' src={YoutubeImage} alt="" srcSet="" />
                        </a>
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center p-4">
                    <div>
                        <h3 className='youtube-text'>আমরা ‘অডিও বাংলা হাদিস’ ইউটিউব চ্যানেল প্রতি সপ্তাহে গড়ে ৩-৪ টি করে ভিডিও প্রকাশ করার চেষ্টা করি।
                            আমাদের ভিডিওগুলো থেকে হাদিস শোনার পাশাপাশি পড়তেও পারবেন।
                            প্রায় ৭০ হাজার ইমানদ্বার মানুষের একটি বিশাল পরিবার আমরা। </h3>
                        <a href="https://www.youtube.com/c/AudioBanglaHadis" target="_blank" rel="noopener noreferrer" className='btn btn-danger mt-4 px-4'>অডিও বাংলা হাদিস ইউটিউব চ্যানেল</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YoutubePromo;