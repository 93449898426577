import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';
import HomeIcon from '../../Images/home.png';
import BukhariIcon from '../../Images/bukhari.png';
import TirmidhiIcon from '../../Images/tirimzi.png';

const Navigation = () => {
    return (

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link to="/" className="navbar-brand navigation-logo" href="#">Audio Bangla Hadis</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <Link to="/" className="nav-link active" aria-current="page"> <img className="navigation-icon" src={HomeIcon} alt="" />হোম</Link>
                        <Link to="/chapters/bukhari" className="nav-link"><img className="navigation-icon" src={BukhariIcon} alt="" />সহীহ আল-বুখারী</Link>
                        <Link to="/chapters/trimidhi" className="nav-link"><img className="navigation-icon" src={TirmidhiIcon} alt="" />জামি আত তিরমিজী</Link>
                    </div>
                </div>
            </div>
        </nav>
        // <div className="">
        //     <div className="navigation-brand container mt-3">
        //         <div className='text-center'>
        //             <Link to="/" className="text-center mb-0 navigation-logo">Audio Bangla Hadis</Link>
        //         </div>
        //         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        //             {/* <span className="navbar-toggler-icon"></span> */}
        //             <i className="fas fa-bars"></i>
        //         </button>
        //     </div>

        //     <nav className="navbar navbar-expand-lg navbar-light">
        //         <div className="container-fluid">
        //             <div className="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
        //                 <div className="navbar-nav">
        //                     <Link to="/" className="nav-link active" aria-current="page"> <img className="navigation-icon" src={HomeIcon} alt="" />হোম</Link>
        //                     <Link to="/chapters/bukhari" className="nav-link"><img className="navigation-icon" src={BukhariIcon} alt="" />সহীহ আল-বুখারী</Link>
        //                     <Link to="/chapters/trimidhi" className="nav-link"><img className="navigation-icon" src={TirmidhiIcon} alt="" />জামি আত তিরমিজী</Link>
        //                 </div>
        //             </div>
        //         </div>
        //     </nav>
        // </div>
    );
};

export default Navigation;