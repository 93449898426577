import React from 'react';
import './Loading.css';

const Loading = (props) => {
    const {text} = props;
    return (
        <div className="loading d-flex align-items-center justify-content-center">
            <div className="spinner-border text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <h2 className="ms-3 mb-0"> {text} লোড হচ্ছে....</h2>
        </div>
    );
};

export default Loading;