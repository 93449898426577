import React from 'react';
import './ChapterCard.css';

const ChapterCard = (props) => {
    const { chapterNo, chapterName, chapterStart, chapterEnd } = props.chapter;
    const { chapterCardOnClick } = props;


    let banglaNumber = {
        0: "০",
        1: "১",
        2: "২",
        3: "৩",
        4: "৪",
        5: "৫",
        6: "৬",
        7: "৭",
        8: "৮",
        9: "৯",
      };
      const engNumbertoBanglaNumber = (str) => {
        for (var x in banglaNumber) {
          str = str.replace(new RegExp(x, "g"), banglaNumber[x]);
        }
        return str;
      };

    const chapterStart_bangla = engNumbertoBanglaNumber(chapterStart);
    const chapterEnd_bangla = engNumbertoBanglaNumber(chapterEnd);
    let totalHadith = chapterEnd - chapterStart + 1;
    const totalHadith_bangla = engNumbertoBanglaNumber(totalHadith.toString());
    

    return (
        <div onClick={() => chapterCardOnClick(chapterNo)} className="col chapter-card">
            <div className="card h-100">               
                <div className="card-body text-center pb-0 px-1">
                    <h6 className="fw-bold">হাদিস: {chapterStart_bangla} - {chapterEnd_bangla}</h6>
                    <h4 className="card-title">{chapterName}</h4>
                </div>
                <div className="card-footer text-center px-1">
                    <p>হাদিস সংখ্যা: {totalHadith_bangla} টি</p>
                </div>
            </div>
        </div>
    );
};

export default ChapterCard;