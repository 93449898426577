import './App.css';
import Navigation from './Components/Navigation/Navigation';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from './Components/Home/Home';
import HadithChapters from './Components/HadithChapters/HadithChapters';
import HadithPlayer from './Components/HadithPlayer/HadithPlayer';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="app">
      <Navigation></Navigation>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="chapters">
          <Route path=":kitab" element={<HadithChapters />} />
          <Route path=":kitab/:chapter" element={<HadithPlayer />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to="/" />}
        />
      </Routes>
      <Footer></Footer>
    </div>

  );
}

export default App;
