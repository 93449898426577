import React from 'react';
import './Pillars.css';
import headerBG from '../../../Images/header.jpg';
import { useNavigate } from 'react-router-dom';

const Pillars = () => {
    const navigate = useNavigate();

    const pillarCardOnClick = route => {
        navigate(route);
    }


    return (
        <div className="container">
            <h1
                style={{ borderImage: `url(${headerBG}) 30 stretch` }}
                className="kitabs-title">ইসলামের ৫টি স্তম্ভভিত্তিক হাদিসসমূহ</h1>

            <div className="container my-5">
                <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 g-3 justify-content-center my-5">
                    <div onClick={() => pillarCardOnClick('chapters/bukhari/2')} className="col pillar-card">
                        <div className="card h-100 py-4">
                            <div className="card-body text-center pb-0 pt-4">
                                <h3 className="card-title">ইমান</h3>
                            </div>
                            <div className="card-footer text-center px-1 pb-4">
                                <p className='mb-0'>হাদিস সংখ্যা: ৫০ টি</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => pillarCardOnClick('chapters/bukhari/8')} className="col pillar-card">
                        <div className="card h-100 py-4">
                            <div className="card-body text-center pb-0 pt-4">
                                <h3 className="card-title">সালাত</h3>
                            </div>
                            <div className="card-footer text-center px-1 pb-4">
                                <p className='mb-0'>হাদিস সংখ্যা: ১৫৫ টি</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => pillarCardOnClick('chapters/bukhari/21')} className="col pillar-card">
                        <div className="card h-100 py-4">
                            <div className="card-body text-center pb-0 pt-4">
                                <h3 className="card-title">যাকাত</h3>
                            </div>
                            <div className="card-footer text-center px-1 pb-4">
                                <p className='mb-0'>হাদিস সংখ্যা: ১১২ টি</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => pillarCardOnClick('chapters/bukhari/23')} className="col pillar-card">
                        <div className="card h-100 py-4">
                            <div className="card-body text-center pb-0 pt-4">
                                <h3 className="card-title">রোজা</h3>
                            </div>
                            <div className="card-footer text-center px-1 pb-4">
                                <p className='mb-0'>হাদিস সংখ্যা: ১১২ টি</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => pillarCardOnClick('chapters/bukhari/22')} className="col pillar-card">
                        <div className="card h-100 py-4">
                            <div className="card-body text-center pb-0 pt-4">
                                <h3 className="card-title">হজ্জ</h3>
                            </div>
                            <div className="card-footer text-center px-1 pb-4">
                                <p className='mb-0'>হাদিস সংখ্যা: ৩৪৫ টি</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pillars;