import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Loading from '../Loading/Loading';
import './HadithPlayer.css';
import ReactHtmlParser from "react-html-parser";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const HadithPlayer = () => {
    const [displayChapter, setDisplayChapter] = useState([]);
    const { kitab, chapter } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [chapterDetails, setChapterDetails] = useState([]);
    const [chapterAll, setChapterAll] = useState([]);
    const [chapterAllSerial, setChapterAllSerial] = useState([]);
    const [currentHadith, setCurrentHadith] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const chapterURL = `https://hadisbangla.com/backend/parseChapter.php?kitab=${kitab}&chapterNo=${chapter}`;
        fetch(chapterURL)
            .then(res => res.json())
            .then(data => {
                setChapterDetails(data[0]);

                // --------- Get Chapter Hadith List ----------
                const chapterHadithsURL = `https://hadisbangla.com/backend/parseChapterHadiths.php?kitab=${kitab}&start=${data[0].chapterStart}&end=${data[0].chapterEnd}`;
                fetch(chapterHadithsURL)
                    .then(resAll => resAll.json())
                    .then(dataAll => {
                        setChapterAll(dataAll);
                        changeCurrentHadith(dataAll[0]);
                        setIsLoading(false);
                    })
            });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let hadithSerialList = [];
        chapterAll.forEach(hadith => {
            hadithSerialList.push(parseInt(hadith.serial));
        });
        hadithSerialList.sort(function (a, b) { return a - b });
        setChapterAllSerial(hadithSerialList);
    }, [chapterAll])



    let banglaNumber = {
        0: "০",
        1: "১",
        2: "২",
        3: "৩",
        4: "৪",
        5: "৫",
        6: "৬",
        7: "৭",
        8: "৮",
        9: "৯",
    };
    const engNumbertoBanglaNumber = (str) => {
        for (var x in banglaNumber) {
            str = str.replace(new RegExp(x, "g"), banglaNumber[x]);
        }
        return str;
    };



    const changeCurrentHadith = (hadith) => {
        setCurrentHadith(hadith);
        window.scrollTo(0, 0);  
    }


    const changeToNextHadith = () => {
        const currentPlayingHadith = parseInt(currentHadith.serial);
        if (currentPlayingHadith === chapterAllSerial[chapterAllSerial.length - 1]) {
            alert('অধ্যায়ের শেষ হাদিস');
        }
        else {
            let nextHadithId = currentPlayingHadith + 1;
            const nextHadith = chapterAll?.find(hadith => nextHadithId.toString() === hadith.serial);
            setCurrentHadith(nextHadith);
        }
    }

    const changeToPrevHadith = () => {
        const currentPlayingHadith = parseInt(currentHadith.serial);
        if (currentPlayingHadith === chapterAllSerial[0]) {
            alert('অধ্যায়ের প্রথম হাদিস');
        }
        else {
            let nextHadithId = currentPlayingHadith - 1;
            const nextHadith = chapterAll?.find(hadith => nextHadithId.toString() === hadith.serial);
            setCurrentHadith(nextHadith);
        }
    }

    return (
        <div className="py-3">
            {
                isLoading ? (
                    <Loading text={"অধ্যায়টি"}></Loading>
                )
                    : (
                        <div className='container hadith-player'>
                            <div className="row">
                                <div className="col-md-9 col-12">
                                    <div className="audio-player-container">
                                        <div className="hadith-text-container p-4">
                                            <h3>হাদিস: {engNumbertoBanglaNumber(currentHadith.serial)}</h3>
                                            <div>
                                                <p className='hadith-text'>{ReactHtmlParser(currentHadith.hadis.replace(/(\r\n|\r|\n)/g, '<br>'))}</p>
                                            </div>
                                        </div>
                                        <div className='hadith-audio-container p-4'>
                                            <AudioPlayer
                                                autoPlay
                                                showSkipControls
                                                showJumpControls={false}
                                                customAdditionalControls={[]}
                                                src={currentHadith.audio}
                                                onClickNext={e => changeToNextHadith()}
                                                onClickPrevious={e => changeToPrevHadith()}
                                            // other props here
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3 col-12 mt-3 mt-md-0">
                                    <div className="hadith-list text-center">
                                        <ul>
                                            {
                                                chapterAll.map(hadith => <li
                                                    key={hadith.id}
                                                >
                                                    {
                                                        (currentHadith.serial === hadith.serial) ? (
                                                            <button className='active' onClick={() => changeCurrentHadith(hadith)}>হাদিস: {engNumbertoBanglaNumber(hadith.serial)}</button>
                                                        ) :
                                                            (
                                                                <button onClick={() => changeCurrentHadith(hadith)}>হাদিস: {engNumbertoBanglaNumber(hadith.serial)}</button>
                                                            )
                                                    }
                                                </li>)
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div >
    );
};

export default HadithPlayer;